
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import type Day from '@/modules/common/types/day.type';
import isDayType from '@/modules/common/component-validators/is-date.validator';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import MarketsService, { MarketsServiceS } from '../../markets.service';

@Component
export default class MarketsCalendarItemNoData extends Vue {
    @Inject(MarketsServiceS) private marketsService!: MarketsService;
    @Inject(UserServiceS) userService!: UserService;

    @Prop({
        required: true,
        type: Number,
        validator: isDayType,
    })
    private day!: Day;

    @Prop({
        required: true,
        type: String,
    })
    private source!: string;

    get isOutOfRange() {
        return this.marketsService.isOutOfRange(this.source);
    }

    get dayRoute() {
        const isDayMarketsOpened = this.$route.name!.includes('day-markets-source');
        return {
            name: isDayMarketsOpened ? this.$route.name! : `${this.$route.name!}.day-markets-source`,
            params: { day: String(this.day), source: String(this.source) },
        };
    }
}
